@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body > #root > div {
  min-height: 100vh;
}

.monaco-editor {
  padding-top: 15px;
}

*:not(.monaco-editor *) {
  font-family: 'Roboto', sans-serif;
}

.fill-white {
  fill: white;
}